<template>
  <div v-if="isActive" :id="anchor" ref="container" :class="containerCss"></div>
</template>

<script>
import { getCmsSlotConfigProperty, isCmsSlotActive } from '~/helpers'

export default {
  name: 'CmsElementBsScript',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    script() {
      return getCmsSlotConfigProperty(this.content, 'content') || undefined
    },
    isActive() {
      return isCmsSlotActive(this.content)
    },
    anchor() {
      return getCmsSlotConfigProperty(this.content, 'anchor') || undefined
    },
    containerCss() {
      return getCmsSlotConfigProperty(this.content, 'containerCss') || undefined
    },
  },
  mounted() {
    try {
      const scriptBlock = this.script?.match(/<script([^><]+)>/)?.[1]
      const attributes = scriptBlock?.matchAll(/([\w_-]+)+="([^"]+)"/g)

      if (attributes) {
        const script = document.createElement('script')

        for (const attr of attributes) {
          script[attr[1]] = attr[2]
        }

        if (script.src) {
          this.$refs.container?.appendChild(script)
        }
      }
    } catch (e) {}
  },
}
</script>
